<template>
  <common-form
    #default="{ isValidForm }"
    @submit="submitData"
  >
    <pp-frame>
      <common-loading
        :loading="!qSections ||
          loadingCompletedSections || loadingEpworth"
      >
        <v-alert
          v-if="qSections && qSections.epworth"
          type="success"
        >
          {{ $t('This page has been completed.') }}<br>
          {{ translate(`
          If you need to make changes to this page,
          please contact {doctor} at {practice}!
          `, { doctor, practice }) }}
        </v-alert>
        <template v-else>
          <h2 class="blue-grey--text">
            <p>{{ $t('Epworth Sleep Questionnaire') }}</p>
          </h2>
          <v-alert
            class="body-1 elevation-1"
            type="info"
            border="top"
            colored-border
          >
            {{ translate(`
            Using the following scale,
            choose the most appropriate number for each situation.
            `) }}<br>
            <span class="font-weight-bold success--text">
              0 = {{ $t('No chance of dozing') }}
            </span><br>
            <span class="font-weight-bold amber--text">
              1 = {{ $t('Slight chance of dozing') }}
            </span><br>
            <span class="font-weight-bold warning--text">
              2 = {{ $t('Moderate chance of dozing') }}
            </span><br>
            <span class="font-weight-bold error--text">
              3 = {{ $t('High chance of dozing') }}
            </span>
          </v-alert>
          <v-row v-if="epworthProps.length">
            <v-col
              v-for="(epworth, idx) in epworthProps"
              :key="`epworth-${idx}`"
              class="py-0 py-2"
              cols="12"
              md="6"
            >
              <pp-select
                v-model="epworth.value"
                :title="epworth.epworth"
                :min="0"
                :max="3"
              />
            </v-col>
          </v-row>
          <v-alert
            class="mt-6"
            :type="epworthLevel === 'normal' ||
              epworthLevel === 'average' ? 'info' :
                epworthLevel === 'moderate' ? 'warning' : 'error'"
          >
            {{ $t('Your sleepiness scale is {epworthSum}', { epworthSum }) }},
            <span v-if="epworthLevel === 'normal'">
              {{ $t('which indicates a normal amount of sleepiness.') }}
            </span>
            <span v-else-if="epworthLevel === 'average'">
              {{ $t('which indicates an average amount of sleepiness.') }}
            </span>
            <span v-else-if="epworthLevel === 'moderate'">
              {{ translate(`
              which may indicate excessive sleepiness
              depending on the situation.
              `) }}<br>
              {{ $t('The patient may want to seek medical attention.') }}
            </span>
            <span v-else>
              {{ translate(`
              which indicates excessive sleepiness and
              medical attention should be sought.
              `) }}
            </span>
          </v-alert>

          <h2 class="blue-grey--text mt-6">
            <p>{{ $t('Thornton Snoring Scale') }}</p>
          </h2>
          <v-alert
            class="body-1 elevation-1"
            type="info"
            border="top"
            colored-border
          >
            {{ translate(`
            Using the following scale,
            choose the most appropriate number for each situation.
            `) }}<br>
            <span class="font-weight-bold success--text">
              0 = {{ $t('Never') }}
            </span><br>
            <span class="font-weight-bold amber--text">
              1 = {{ $t('Infrequently (1 night per week)') }}
            </span><br>
            <span class="font-weight-bold warning--text">
              2 = {{ $t('Frequently (2 - 3 nights per week)') }}
            </span><br>
            <span class="font-weight-bold error--text">
              3 = {{ $t('Most of the time (4 or more nights)') }}
            </span>
          </v-alert>
          <v-row v-if="thorntonQuestions.length">
            <v-col
              v-for="(question, idx) in thorntonQuestions"
              :key="idx"
              class="py-0 py-2"
              cols="12"
              md="6"
            >
              <pp-select
                v-model="question.value"
                :title="question.question"
                :min="0"
                :max="3"
              />
            </v-col>
          </v-row>
          <v-alert
            class="mt-6"
            :type="thorntonSum < 5 ? 'info' : 'warning'"
          >
            {{
              $t('Your snoring score is {thorntonSum}', { thorntonSum })
                + ((thorntonSum >= 5)? ',' : '')
            }}
            <span v-if="thorntonSum >= 5">
              {{ translate(`
              which indicates your snoring may be
              significantly affecting your quality of life
              `) }}.
            </span>
          </v-alert>
        </template>
      </common-loading>

      <template #actions>
        <v-btn to="/questionnaire/symptoms">
          <v-icon>mdi-chevron-double-left</v-icon>
          {{ $t('Back') }}
        </v-btn>

        <v-spacer />

        <v-btn
          color="primary"
          type="submit"
          :disabled="!isValidForm"
          :loading="loadingEpworth ||
            submittingEpworth ||
            loadingCompletedSections"
        >
          {{ qSections && qSections.epworth
            ? $t('Next') : $t('Save and Proceed') }}
          <v-icon>mdi-chevron-double-right</v-icon>
        </v-btn>
      </template>
    </pp-frame>
  </common-form>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { utils } from 'ui-common'
import { upperFirst } from 'lodash'
import { useQuestionnaire } from '@/use/questionnaire'
import { useEpworth } from '@/use/epworth'
import { useProfile } from '@/use/profile'

export default {
  setup: (_, { root }) => {
    const {
      primaryProfile
    } = useProfile()

    const {
      qSections,
      loadingCompletedSections
    } = useQuestionnaire()

    const {
      epworthProps,

      loadingEpworth,
      submittingEpworth,
      getEpworth,
      submitEpworth
    } = useEpworth()

    const thorntonQuestions = ref([
      'My snoring affects my relationship with my partner',
      'My snoring causes my partner to be irritable or tired',
      'My snoring requires us to sleep in separate rooms',
      'My snoring is loud',
      'My snoring affects people when I am sleeping away from home'
    ].map((question, idx) => ({
      original: question,
      question: question,
      value: 0,
      id: `snore_${idx + 1}`
    })))
    const epworthSum = computed(() => epworthProps.value
      .reduce((acc, cur) => acc + cur.value, 0))
    const thorntonSum = computed(() => thorntonQuestions.value
      .reduce((acc, cur) => acc + cur.value, 0))
    const epworthLevel = computed(() => {
      if (epworthSum.value < 8) {
        return 'normal'
      } else if (epworthSum.value < 10) {
        return 'average'
      } else if (epworthSum.value < 16) {
        return 'moderate'
      } else {
        return 'severe'
      }
    })

    const epworthPlaceholder = computed(() => {
      const scoreText =
        `The Epworth Sleepiness Scale score was ${epworthSum.value}`

      if (epworthSum.value >= 8 && epworthSum.value < 10) {
        return scoreText + ', which indicates a average amount of sleepiness.'
      } else if (epworthSum.value >= 10 && epworthSum.value < 16) {
        return scoreText +
        ', which may indicate excessive sleepiness' +
        ' depending on the situation. ' +
        'The patient may want to seek medical attention.'
      } else if (epworthSum.value >= 16) {
        return scoreText +
        ', which indicates excessive sleepiness and ' +
        'medical attention should be sought.'
      }
      return scoreText + ', which indicates a normal amount of sleepiness.'
    })

    const submitData = async () => {
      if (qSections.value.epworth) {
        utils.navigate('/questionnaire/prev-treatments')
        return
      }

      const thornton = thorntonQuestions.value.reduce((acc, cur) => {
        acc[cur.id] = cur.value
        return acc
      }, {})

      try {
        await submitEpworth({
          thornton,
          analysis: epworthPlaceholder.value,
          epworth: epworthProps.value,
          totScore: thorntonSum.value,
          epworthScore: epworthSum.value
        })
        utils.navigate('/questionnaire/prev-treatments')
      } catch (err) {}
    }

    onMounted(getEpworth)

    return {
      qSections,
      loadingCompletedSections,

      loadingEpworth,
      submittingEpworth,

      thorntonSum,
      thorntonQuestions: computed(() =>
        thorntonQuestions.value.map(element => {
          element.question = root.$i18n.t(element.original)
          return element
        })
      ),
      epworthSum,
      epworthProps,
      epworthLevel,

      submitData,
      doctor: computed(() =>
        primaryProfile.value &&
        upperFirst(primaryProfile.value.doctor.name)
      ),
      practice: computed(() =>
        primaryProfile.value &&
        upperFirst(primaryProfile.value.doctor.practice)
      )
    }
  }
}
</script>
