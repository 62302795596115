import { ref, Ref } from '@vue/composition-api'
import repositories from '@/repositories'
import { parentProfile } from './profile'
import { useQuestionnaire } from './questionnaire'

export const useEpworth = () => {
  const epworthProps: Ref<any[]> = ref([])
  const loadingEpworth = ref(false)
  const submittingEpworth = ref(false)

  const { getCompletedSections } = useQuestionnaire()

  const getEpworth = async () => {
    loadingEpworth.value = true
    try {
      const res = await repositories.epworth
        .getEpworthSleepinessScale()
      epworthProps.value = res.data
        .map((epworth: any) => ({ ...epworth, value: 0 }))
    } catch (err) {} finally {
      loadingEpworth.value = false
    }
  }

  const submitEpworth = async ({
    thornton,
    analysis,
    epworth,
    totScore,
    epworthScore
  }: any) => {
    submittingEpworth.value = true
    const {
      patientid,
      userid,
      docid,
      lastSymptom: { qPage1Id },
      symptomsStatus,
      treatmentsStatus,
      historyStatus
    } = parentProfile.value

    try {
      await repositories.epworth.submitSleepTests({
        info: {
          patientid,
          userid,
          docid,
          referenceId: 0,
          analysis,
          epworthid: epworth.reduce((acc: string, cur: any) =>
            `${acc}${cur.epworthid}|${cur.value}~`, '')
        }
      })

      await repositories.thorntons.submitThorntons({
        info: {
          patientid,
          userid,
          docid,
          referenceId: 0,
          ...thornton,
          totScore
        }
      })

      await repositories.symptoms.updateSymptom({
        qPage1Id,
        info: {
          patientid,
          docid,
          userid,
          tss: totScore,
          ess: epworthScore
        }
      })
      const havePreMedication = parentProfile.value.premedcheck
      const preMedication = parentProfile.value.premed
      if (symptomsStatus >= 1 && treatmentsStatus >= 1 && historyStatus >= 1) {
        parentProfile.value = (await repositories.profile
          .updatePatientQuestionnaire({
            patientid,
            premedcheck: havePreMedication ? 1 : 0,
            premed: havePreMedication ? preMedication : '',

            symptomsStatus: 2,
            sleepStatus: 2,
            treatmentsStatus: 2,
            historyStatus: 2
          })
        ).data
      } else {
        parentProfile.value = (await repositories.profile
          .updatePatientQuestionnaire({
            patientid,
            premedcheck: havePreMedication ? 1 : 0,
            premed: havePreMedication ? preMedication : '',

            symptomsStatus: symptomsStatus,
            sleepStatus: 1,
            treatmentsStatus: treatmentsStatus,
            historyStatus: historyStatus
          })
        ).data
      }

      await getCompletedSections()
    } catch (err) {} finally {
      submittingEpworth.value = false
    }
  }

  return {
    epworthProps,

    loadingEpworth,
    getEpworth,

    submittingEpworth,
    submitEpworth
  }
}
